<template>
  <v-expansion-panels focusable>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Regras Globais</v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content> </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Regras para Etapas</v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content> </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Regras para Subetapas</v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <!-- <span v-if="open"> Insira um valor para nota abaixo da média e acima da média</span> -->
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content> </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="yellow lighten-5">
      <v-expansion-panel-header class="titleBimestre py-0">
        <template>
          <v-row no-gutters>
            <v-col cols="4"> Regras para Notas</v-col>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute> </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="caption mt-3 mb-2">Regra de alterações de cores das notas no boletim</div>
        <div class="row">
          <div class="col-3">
            <v-select
              :items="tipos_notas"
              :item-text="(dado) => dado.title"
              :item-value="(dado) => dado.slug"
              label="Tipo de Nota"
              v-model="tipo_nota"
              outlined
            >
            </v-select>
          </div>
          <div class="col-3">
            <v-select
              :items="condicoes_notas"
              :item-text="(dado) => dado.title"
              :item-value="(dado) => dado.slug"
              label="Condição"
              v-model="condicao"
              outlined
            >
            </v-select>
          </div>
          <div class="col-2">
            <v-text-field v-model="numero" label="Número" outlined />
          </div>
          <div class="col-2">
            <v-select
              :items="cores_notas"
              :item-text="(dado) => dado.title"
              :item-value="(dado) => dado.slug"
              label="Cor"
              v-model="cor"
              outlined
            >
            </v-select>
          </div>
          <div class="col-2">
            <v-btn class="success mt-2" large>
              <v-icon>done</v-icon>
              Salvar
            </v-btn>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "configuracoes-media",
  props: {
    circuitoId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    tipos_notas: [
      {
        slug: "global",
        title: "Global",
      },
      {
        slug: "normal",
        title: "Normal",
      },
    ],
    condicoes_notas: [
      {
        slug: "maior_que",
        title: "Maior que",
      },
      {
        slug: "menor_que",
        title: "Menor que",
      },
      {
        slug: "igual_a",
        title: "Igual a",
      },
      {
        slug: "diferente",
        title: "Diferente de",
      },
    ],
    cores_notas: [
      {
        slug: "blue",
        title: "Azul",
      },
      {
        slug: "red",
        title: "Vermelho",
      },
      {
        slug: "black",
        title: "Preto",
      },
      {
        slug: "yellow",
        title: "Amarelo",
      },
    ],
  }),
  mounted() {
    this.loadCircuito();
  },
  methods: {},
};
</script>
